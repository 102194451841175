import React from "react";
import { graphql, useStaticQuery } from "gatsby"

import Whatsapp from './../../images/whatsapp.svg'
import Envelope from './../../images/envelope.svg'
import Top from './../../images/top.svg'
import Telegram from './../../images/telegram-brands.svg'

const Sidebar = () => {
  const data = useStaticQuery(graphql`{
    wp {
        themeGeneralSettings {
          commonSetting {
            siderBar {
              email
              telegram
              whatsApp
            }
          }
        }
      }
    }`)
  const sidebar = data.wp.themeGeneralSettings.commonSetting.siderBar;

  const toTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <ul className="fixed right-0 z-50 hidden px-1 -translate-y-1/2 md:block top-1/2 rounded-tl-xl rounded-bl-xl bg-gradient-to-br from-[var(--color-linear-primary)] to-[var(--color-linear-secondary)]">

      <li className="px-2 py-3 border-b border-white">
        <a href={sidebar.whatsApp} rel="noreferrer" target="_blank">
          <Whatsapp className=" w-6 h-6 mx-auto transition-all duration-300 hover:scale-[1.15]" />
        </a>
      </li>
      <li className="px-2 py-3 border-b border-white">
        <a href={`mailto:${sidebar.email}`} rel="noreferrer" target="_blank">
          <Envelope className=" w-[18px] mx-auto transition-all duration-300 hover:scale-[1.15]" />
        </a>
      </li>
      <li className="px-2 py-3 border-b border-white">
        <a href={sidebar.telegram} rel="noreferrer" target="_blank">
          <Telegram className="h-[18px] w-[18px] mx-auto transition-all duration-300 hover:scale-[1.15]" />
        </a>
      </li>
      <li className="px-2 py-3">
        <Top onClick={toTop} role="presentation" className="cursor-pointer h-6 mx-auto transition-all duration-300 hover:scale-[1.15]" />
      </li>
    </ul>
  )
}

export default Sidebar;