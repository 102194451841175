import * as React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { IconContext } from "react-icons"
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa'

import BusinessBanner from "../components/sections/businessBanner"
import Logo from '../images/ced-logo.svg';

const Footer = ({ location }) => {
  const isOrderPage = location.pathname === '/order';

  const data = useStaticQuery(graphql`{
    wpMenu(name: {eq: "Footer Menu"}) {
      menuItems {
        nodes {
          id
          label
          url
        }
      }
    }
    wp {
      themeGeneralSettings {
        commonSetting {
          logoWhite {
            localFile {
              url
            }
          }
          socialMedia {
            social
            socialLink
          }
          location {
            address
            location
          }
          organisedBy {
            sourceUrl
          }
        }
      }
    }
  }`)

  const common = data.wp.themeGeneralSettings.commonSetting;

  const meidaIconShow = type => {
    switch (type) {
      case 'instagram':
        return <FaInstagram />
      case 'twitter':
        return <FaTwitter />
      case 'linkedin':
        return <FaLinkedinIn />
      case 'facebook':
        return <FaFacebookF />
      default:
        return <FaYoutube />
    }
  }

  const logoWhite =
    data.wp.themeGeneralSettings.commonSetting.logoWhite.localFile.url

  return (
    <footer className="relative pb-5 text-xs text-white bg-[var(--color-footer-bg)] pt-28 sm:pt-32 md:pt-40 sm:text-sm">
      {
        isOrderPage ? (
          <div className="-mt-20"></div>
        ) : (
          <div className="container absolute top-0 -translate-x-1/2 -translate-y-1/2 left-1/2">
            <BusinessBanner />
          </div>
        )
      }


      <div className="container mx-auto">

        <div className="flex flex-wrap justify-between lg:mb-10">

          <div className="w-full lg:w-4/12">
            <Link to="/" className="max-w-[420px] mx-auto block">
              <Logo className="w-[215px] md:w-[275px] mb-8 lg:mb-14" />
              <img className="w-[215px] md:w-[275px] mb-8 lg:mb-14" src={logoWhite} alt="" />
            </Link>

            <ul className="hidden lg:flex justify-between w-[275px]">
              <IconContext.Provider value={{ size: 21 }}>
                {
                  common.socialMedia.map((media, i) => {
                    return (
                      <li key={`media-${i}`}>
                        <a href={media.socialLink} target="_blank" rel="noreferrer" >{meidaIconShow(media.social)}</a>
                      </li>
                    )

                  })
                }
              </IconContext.Provider>
            </ul>
          </div>

          <ul className="max-w-[420px] mx-auto text-left order-3 lg:order-2 w-full lg:w-4/12 flex flex-col justify-start flex-wrap h-36 lg:h-48">
            {
              data.wpMenu.menuItems.nodes.map((item, i) => {
                return (
                  <li key={item.id} className="h-12 uppercase">
                    <Link to={item.url} className={`${(i > 2 && i < 6) && 'mx-auto'} ${i >= 6 && 'ml-auto'} lg:ml-0 lg:mr-0 w-[80px] block`}>{item.label}</Link>
                  </li>
                )
              })
            }
          </ul>

          <ul className="order-2 w-full pb-5 mb-5 border-b lg:pb-0 lg:mb-0 border-white/20 lg:border-b-0 lg:order-3 lg:w-4/12">
            {
              common.location.map((item, i) => {
                return (
                  <li key={`loaction-${i}`} className="max-w-[420px] mx-auto flex mb-4 leading-relaxed">
                    <span className="mr-3 uppercase whitespace-nowrap sm:mr-4">{item.location} :</span>
                    <div className="capitalize " dangerouslySetInnerHTML={{ __html: item.address }} />
                  </li>
                )
              })
            }
            <li className="max-w-[420px] mx-auto flex items-center leading-relaxed">
              <span className="mr-3 uppercase whitespace-nowrap sm:mr-4">Organised by</span>
              <img className=" w-16 sm:w-[110px]" src={common.organisedBy.sourceUrl} alt="" />
            </li>
          </ul>
        </div>

        <ul className="flex lg:hidden mx-auto justify-between w-[210px]">
          <IconContext.Provider value={{ size: 16 }}>
            {
              common.socialMedia.map((media, i) => {
                return (
                  <li key={`media-${i}`}>
                    <a href={media.socialLink} target="_blank" rel="noreferrer" >{meidaIconShow(media.social)}</a>
                  </li>
                )

              })
            }
          </IconContext.Provider>
        </ul>

        <div className="pt-5 mt-5 text-center border-t lg:pt-0 lg:mt-0 lg:flex lg:justify-center border-white/20 lg:border-t-0">
          <p className="mb-1 lg:mb-0">Copyright © {new Date().getFullYear()} Crypto Expo Dubai. All Rights Reserved.</p>
          <p>Designed by <a href="https://www.digitecit.com.au" target="_blank" rel="noreferrer" className="underline">Digitec IT</a>.</p>
        </div>

      </div>
    </footer>
  )
}

Footer.defaultProps = {
}

export default Footer