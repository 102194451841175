/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import Sidebar from "./sections/sidebar"
import clsx from "clsx"
import "./index.css"
import "./backgroundAnimation.css"

import 'swiper/css'
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import "lightgallery/css/lg-video.css";
import BackgroundAnimation from "./backgroundAnimation"

const Layout = ({ children, location }) => {

  const data = useStaticQuery(graphql`{
    wp {
      themeGeneralSettings {
        commonSetting {
          themeStyle
        }
      }
    }
  }`)

  const themeStyle = data.wp.themeGeneralSettings.commonSetting.themeStyle;

  return (
    <div className={clsx(themeStyle)}>
      <div className="min-h-[calc(100vh-80px)] lg:min-h-[calc(100vh-87.5px)] flex flex-col mb-[72px] md:mb-[87px] lg:mb-[87px]">
        <Header location={location} />
        <div className="relative flex w-full grow pb-[60px] sm:pb-[85px]">
          <BackgroundAnimation location={location} />
          <main className="w-full ">{children}</main>
        </div>
        <Footer location={location} />
      </div>
      <Sidebar />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
