import React, { useCallback, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import Logo from "../images/ced-logo.svg"
import ArrowUp from "../images/arrow-up-right.svg"
import clsx from "clsx"

const Header = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      wpMenu(locations: { eq: GATSBY_HEADER_MENU }) {
        id
        name
        menuItems {
          nodes {
            id
            label
            path
            parentId
            url
          }
        }
      }
      wp {
        themeGeneralSettings {
          commonSetting {
            logo {
              localFile {
                url
              }
            }
            logoWhite {
              localFile {
                url
              }
            }
          }
        }
      }
    }
  `)

  const [isScrolled, setIsScrolled] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isHeaderShowing, setHeaderShowing] = useState(true)
  const lastScrollTop = useRef(100000)

  const openMenu = () => {
    setIsMenuOpen(true)
    document.querySelector("body").classList.add("no-scroll")
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
    document.querySelector("body").classList.remove("no-scroll")
  }

  const scrollHandler = useCallback(() => {
    setIsScrolled(window.scrollY > 50)

    const st = window.pageYOffset || document.documentElement.scrollTop

    if (st < 50) {
      setHeaderShowing(true)
      return
    }

    setHeaderShowing(st < lastScrollTop.current)
    lastScrollTop.current = st <= 0 ? 0 : st
  }, [])

  const resizeHandler = useCallback(() => {
    if (window.innerWidth > 1320) {
      closeMenu()
    }
  }, [])

  useEffect(() => {
    scrollHandler()
    window.addEventListener("scroll", scrollHandler)
    window.addEventListener("resize", resizeHandler)

    return () => {
      window.removeEventListener("scroll", scrollHandler)
      window.removeEventListener("resize", resizeHandler)
    }
  }, [scrollHandler, resizeHandler])

  const isFrontPage = location.pathname === "/"

  const logoWhite =
    data.wp.themeGeneralSettings.commonSetting.logoWhite.localFile.url
  const logoDark = data.wp.themeGeneralSettings.commonSetting.logo.localFile.url

  return (
    <>
      <header
        id="page-header"
        className={clsx(
          "z-50 w-full top-0 fixed transition-[transform,color,background-color] duration-300",
          isFrontPage ? "frontpage" : "",
          isScrolled ? "scrolled" : "",
          isHeaderShowing ? "translate-y-0" : "-translate-y-full"
        )}
      >
        <div
          className={`container flex items-center justify-between transition-[height] mx-auto ${
            isScrolled ? "h-[60px] md:h-[85px]" : "h-[80px] md:h-[115px]"
          }`}
        >
          <Link to="/" className="flex items-center">
            {/* <Logo className="w-[138px] md:w-[200px]" /> */}
            {isScrolled || !isFrontPage ? (
              <img
                src={logoDark}
                className="object-contain w-[138px] md:w-[200px] h-auto"
                alt="logo_dark"
              />
            ) : (
              <img
                src={logoWhite}
                className="object-contain w-[138px] md:w-[200px] h-auto"
                alt="logo_light"
              />
            )}
            <span className="sr-only">Crypto Expo Dubai</span>
          </Link>

          <nav className="hidden xl:flex">
            <ul className="flex items-center space-x-2.5 xl:space-x-6">
              {data.wpMenu.menuItems.nodes.map(menuItem => {
                if (menuItem.parentId !== null) return null

                const children = data.wpMenu.menuItems.nodes
                  .map(child => {
                    if (child.parentId === menuItem.id) {
                      let url = child.url
                      if (child.url.includes("#")) {
                        url = menuItem.url + child.url
                      }
                      return (
                        <li
                          key={child.id}
                          className="inline-block pb-3 last:pb-0"
                        >
                          <Link
                            className="inline-block !text-primary after:transition-[width] after:duration-300 after:absolute after:bottom-0 after:left-0 relative after:w-0 after:h-[1px] after:bg-dark hover:after:w-full"
                            to={url}
                          >
                            {child.label}
                          </Link>
                        </li>
                      )
                    } else {
                      return null
                    }
                  })
                  .filter(child => {
                    return child !== null
                  })

                return menuItem.parentId === null ? (
                  <li
                    key={menuItem.id}
                    className="relative flex items-center h-full pb-4 -mb-4 text-center group"
                  >
                    <Link
                      to={menuItem.url}
                      className="flex items-center space-x-1.5 font-semibold"
                    >
                      <span className="whitespace-nowrap text-[15px]">
                        {menuItem.label}
                      </span>
                      {children.length > 0 && (
                        <span className="transition-transform duration-300 group-hover:rotate-180">
                          <svg
                            width="14"
                            height="9"
                            viewBox="0 0 14 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L7 7.52033L13 1"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      )}
                    </Link>

                    {children.length > 0 && (
                      <ul className="hidden group-hover:inline-flex  flex-col absolute left-1/2 -translate-x-1/2 space-y-1 top-full min-w-[150px] px-5 py-7 text-sm bg-white rounded-[10px]">
                        {children}
                      </ul>
                    )}
                  </li>
                ) : null
              })}
              <li className="relative flex items-center h-full pb-4 pl-4 -mb-4 space-x-4 group">
                <Link
                  to={"/become-sponsor"}
                  className={`btn ${
                    isFrontPage
                      ? isScrolled
                        ? "text-linear"
                        : ""
                      : "text-linear"
                  }`}
                >
                  <span>Become Sponsor</span>
                </Link>
                <Link to={"/register"} className="btn linear">
                  <span>Get Tickets</span>
                </Link>
              </li>
            </ul>
          </nav>
          <div className="block xl:hidden">
            {isMenuOpen ? (
              <button type="button" onClick={closeMenu}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                <span className="sr-only">Close Menu</span>
              </button>
            ) : (
              <button type="button" className="" onClick={openMenu}>
                <svg
                  className="w-8 h-8"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 10H7"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21 6H3"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21 14H3"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21 18H7"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="sr-only">Open menu</span>
              </button>
            )}
          </div>
        </div>
      </header>

      <div
        role="presentation"
        className={`${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition duration-500 fixed top-0 left-0 right-0 bottom-0 z-[1000] h-screen xl:hidden`}
        onClick={closeMenu}
      >
        <div className="h-full bg-white w-full sm:w-[400px] max-h-screen flex-col flex relative">
          <div className="flex items-center justify-between w-full px-4 pt-4 pb-10">
            <div className="flex items-center space-x-3.5 sm:space-x-8">
              <Link to="/">
                <Logo className="fill-primary" />
              </Link>
            </div>
            <button type="button" onClick={closeMenu} className="sm:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <ul
            role="presentation"
            className="flex flex-col items-start w-full h-full pb-20 mr-auto overflow-auto bg-white space-y-9 lg:space-y-12 text-dark"
            onClick={e => e.stopPropagation()}
          >
            {data.wpMenu.menuItems.nodes.map(menuItem => {
              if (menuItem.parentId !== null) return null

              const children = data.wpMenu.menuItems.nodes
                .filter(child => child.parentId === menuItem.id)
                .map(child => {
                  let url = child.url
                  if (child.url.includes("#")) {
                    url = menuItem.url + child.url
                  }
                  return (
                    <li key={child.id} className="pb-3 last:pb-0">
                      <Link onClick={closeMenu} to={url}>
                        - {child.label}
                      </Link>
                    </li>
                  )
                })

              return (
                <li
                  key={menuItem.id}
                  className="w-full px-4 md:w-auto text-start"
                >
                  <Link
                    to={menuItem.url}
                    onClick={closeMenu}
                    className="flex items-center space-x-3 text-xl font-semibold sm:text-2xl"
                  >
                    <span>{menuItem.label}</span>
                    <ArrowUp className="w-4 pt-0.5" />
                  </Link>
                  {children.length > 0 && (
                    <ul className="mt-4 ml-6 text-base font-semibold">
                      {children}
                    </ul>
                  )}
                </li>
              )
            })}
            <li className="w-full px-4 md:w-auto text-start">
              <Link
                to={"/become-sponsor"}
                onClick={closeMenu}
                className="flex items-center space-x-3 text-xl font-semibold sm:text-2xl"
              >
                <span>Become Sponsor</span>
                <ArrowUp className="w-4 pt-0.5" />
              </Link>
            </li>
            <li className="w-full px-4 md:w-auto text-start">
              <Link
                to={"/register"}
                onClick={closeMenu}
                className="flex items-center space-x-3 text-xl font-semibold sm:text-2xl"
              >
                <span>Get Tickets</span>
                <ArrowUp className="w-4 pt-0.5" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
