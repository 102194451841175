
import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby"

const BusinessBanner = () => {
  const data = useStaticQuery(graphql`{
    wp {
    themeGeneralSettings {
      ...WpThemeGeneralSettingsFragment
      commonSetting {
        businessTitle
      }
    }
  }
}`)

  const business = data.wp.themeGeneralSettings.commonSetting;

  return (
    <section
      className="container div-rounded p-4 md:p-10 lg:p-16 mx-auto text-white bg-gradient-to-r from-[var(--color-linear-primary)] to-[var(--color-linear-secondary)]"
    >
      <div className="flex flex-wrap items-center justify-between lg:flex-nowrap">
        <h2 className="section-title banner-title !mb-4 lg:!mb-0">{business.businessTitle}</h2>
        <div className="flex space-x-5 align-center">
          <Link to={business.becomeSponsorLink.link} className="btn">Become sponsor</Link>
          <Link to={business.getTicketsLink.link} className="btn solid">Get tickets</Link>
        </div>
      </div>
    </section>
  )
}

export default BusinessBanner;