import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropsType from 'prop-types';
import moment from 'moment';
import './style.css';

const FlipCountdown = (props) => {
  const [completed, setCompleted] = useState(false);
  const clock = {
    year: {
      title: 'Years',
      value: useState(0),
      prevValue: useState(0),
      ref: useRef(null)
    },
    month: {
      title: 'Months',
      value: useState(0),
      prevValue: useState(0),
      ref: useRef(null)
    },
    day: {
      title: 'Days',
      value: useState(0),
      prevValue: useState(0),
      ref: useRef(null)
    },
    hour: {
      title: 'Hours',
      value: useState(0),
      prevValue: useState(0),
      ref: useRef(null)
    },
    minute: {
      title: 'Minutes',
      value: useState(0),
      prevValue: useState(0),
      ref: useRef(null)
    },
    second: {
      title: 'Seconds',
      value: useState(0),
      prevValue: useState(0),
      ref: useRef(null)
    }
  };

  const {
    theme = 'dark',
    size = 'medium',
    endAt = moment(),
    hideYear,
    hideMonth,
    hideDay,
    hideHour,
    hideMinute,
    hideSecond,
    titlePosition = 'top',
    endAtZero,
    onTimeUp = () => null,
  } = props;
  
  const interval = useRef(null);

  const prev = useRef(moment.duration(moment().diff(moment())));

  const processClock = useCallback(() => {
    const then = moment(endAt);
    const value = moment.duration(then.diff(moment()));

    if (value.milliseconds() < 0) {
      setCompleted(true);
      clearInterval(interval.current);
      onTimeUp();
      return;
    }

    // Year
    if (!hideYear) {
      clock.year.value[1](value.years());
      clock.year.prevValue[1](prev.current.years());
    }

    // Months
    if (!hideMonth) {
      clock.month.value[1](value.months());
      clock.month.prevValue[1](prev.current.months());
    }

    // Days
    if (!hideDay) {

      if (hideYear && hideMonth) {
        value._days = Math.floor(value._milliseconds / (1000 * 60 * 60 * 24))
        clock.day.value[1](value._days);
      } else {
        clock.day.value[1](value.days());
      }

      clock.day.prevValue[1](prev.current.days());
    }

    // Hours
    if (!hideHour) {
      clock.hour.value[1](value.hours());
      clock.hour.prevValue[1](prev.current.hours());
    }

    // Minutes
    if (!hideMinute) {
      clock.minute.value[1](value.minutes());
      clock.minute.prevValue[1](prev.current.minutes());
    }

    // Seconds
    if (!hideSecond) {
      clock.second.value[1](value.seconds());
      clock.second.prevValue[1](prev.current.seconds());
    }

    prev.current = value;
  }, []);

  const getPiece = (key) => {
    const data = clock[key];
    const [value] = data.value;

    return (
      <span className='flip-countdown-piece' ref={data.ref}>
        {'top' === titlePosition && (
          <span className='flip-countdown-title'>
            {props[`${key}Title`] || data.title}
          </span>
        )}

        <span className='flip-countdown-card'>
          <span className='flip-countdown-card-sec'>
            <span className='card__top'>{value}</span>
            <span className='card__bottom' data-value={value} />
          </span>
        </span>
        {'bottom' === titlePosition && (
          <span className='flip-countdown-title'>
            {props[`${key}Title`] || data.title}
          </span>
        )}
      </span>
    );
  };

  useEffect(() => {
    processClock();
    interval.current = setInterval(() => {
      processClock();
    }, 1000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [endAt, processClock]);

  if (completed && !endAtZero) {
    return <div className='flip-countdown'>{props.children || endAt}</div>;
  }

  return (
    <div className={`flip-countdown theme-${theme} size-${size}`}>
      {!hideYear && getPiece('year')}
      {!hideMonth && getPiece('month')}
      {!hideDay && getPiece('day')}
      {!hideHour && getPiece('hour')}
      {!hideMinute && getPiece('minute')}
      {!hideSecond && getPiece('second')}
    </div>
  );
};

FlipCountdown.propTypes = {
  /**
   * The theme of Flip countdown component.
   */
  theme: PropsType.oneOf(['light', 'dark']),

  /**
   * The size of Flip countdown component card.
   */
  size: PropsType.oneOf(['large', 'medium', 'small', 'extra-small']),

  /**
   * Date/time value for the counter.
   */
  endAt: PropsType.string,

  /**
   * Hide Year counter.
   */
  hideYear: PropsType.bool,

  /**
   * Hide Month counter.
   */
  hideMonth: PropsType.bool,

  /**
   * Hide Day counter.
   */
  hideDay: PropsType.bool,

  /**
   * Hide Hour counter.
   */
  hideHour: PropsType.bool,

  /**
   * Hide Minute counter.
   */
  hideMinute: PropsType.bool,

  /**
   * Hide Second counter.
   */
  hideSecond: PropsType.bool,

  /**
   * Title Position.
   */
  titlePosition: PropsType.oneOf(['top', 'bottom']),

  /**
   * Change year's title.
   */
  yearTitle: PropsType.string,

  /**
   * Change month's title.
   */
  monthTitle: PropsType.string,

  /**
   * Change day's title.
   */
  dayTitle: PropsType.string,

  /**
   * Change hour's title.
   */
  hourTitle: PropsType.string,

  /**
   * Change minute's title.
   */
  minuteTitle: PropsType.string,

  /**
   * Change second's title.
   */
  secondTitle: PropsType.string,

  /**
   * End at Zero.
   */
  endAtZero: PropsType.bool,

  /**
   * Time Up event
   */
  onTimeUp: PropsType.func
};

export default FlipCountdown;