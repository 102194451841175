/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

import React from "react";
import TimeCountdown from "./src/components/sections/timeCountdown";
import Layout from "./src/components/layout";

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Layout {...props}>
        {element}
        <div className={props.uri === '/order' ? 'hidden lg:block' : ''}>
          <TimeCountdown />
        </div>
      </Layout>
    </>
  )
}