import React from 'react'

const BackgroundAnimation = ({ location }) => {
  const isFrontPage = location.pathname === '/'

  // useEffect(() => {
  //   let circleAnimation = gsap.to(circle1.current, {
  //     x: "random(-15, 40)",
  //     y: "random(-15, 40)",
  //     ease: "none",
  //     duration: 2,
  //     repeat: -1,
  //     repeatRefresh: true,
  //   })

  //   return () => {
  //     circleAnimation.kill()
  //   }
  // })


  return (
    <div className='absolute inset-0 w-full h-full overflow-hidden pointer-events-none -z-10 bg-[#f4f6fa]'>
      {
        isFrontPage ? (
          <div className='absolute mt-[calc(100vh-80px)] lg:mt-[calc(100vh-87.5px)] w-full'>
            <div className='absolute top-[0px] left-[-200px] w-[520px] h-[400px] rounded-full circle-linear-1 blur-[200px] opacity-70' />
            <div className='hidden md:block absolute top-[-100px] right-[-200px] w-[520px] h-[440px] rounded-full circle-linear-2 blur-[150px] opacity-70' />
            <div className='absolute top-[1000px] right-[150px] w-[320px] h-[250px] rounded-full circle-linear-1 blur-[150px] opacity-50' />

            <div className='absolute top-[1400px] left-[250px] w-[520px] h-[440px] rounded-full circle-linear-2 blur-[200px] opacity-70' />

            <div className='absolute top-[2500px] right-[-200px] w-[520px] h-[440px] rounded-full circle-linear-2 blur-[150px] opacity-70' />
            <div className='absolute top-[3100px] left-[400px] w-[520px] h-[400px] rounded-full circle-linear-1 blur-[200px] opacity-70' />
            <div className='absolute top-[5000px] right-[-200px] w-[520px] h-[440px] rounded-full circle-linear-2 blur-[150px] opacity-50' />
            <div className='absolute top-[5700px] left-[-200px] w-[520px] h-[400px] rounded-full circle-linear-1 blur-[200px] opacity-70' />

            <div className='absolute top-[7200px] left-[60%] w-[320px] h-[250px] rounded-full circle-linear-1 blur-[150px] opacity-50' />
            <div className='absolute top-[7400px] left-[250px] w-[520px] h-[440px] rounded-full circle-linear-2 blur-[200px] opacity-50' />
          </div>
        ) : (
          <>
            <div className='hidden md:block absolute top-[40px] left-[-200px] w-[520px] h-[400px] rounded-full circle-linear-1 blur-[150px] opacity-50' />
            <div className='hidden md:block absolute left-1/2 -translate-x-1/2 top-[200px] w-[520px] h-[440px] rounded-full circle-linear-2 blur-[150px] opacity-70' />
            <div className='hidden md:block absolute top-[83px] right-[-100px] w-[320px] h-[250px] rounded-full circle-linear-1 blur-[150px] opacity-50' />

            <div className='md:hidden absolute top-[40px] left-[0px] w-[220px] h-[165px] rounded-full circle-linear-1 blur-[150px] opacity-100' />
            <div className='md:hidden absolute top-[250px] right-[-100px] w-[280px] h-[235px] rounded-full circle-linear-2 blur-[150px] opacity-100' />
          </>
        )
      }

    </div>
  )
}

export default BackgroundAnimation