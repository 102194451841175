import React, { useState } from "react"
import FlipCountdown from '../countdown/flipCountdown'
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { IconContext } from "react-icons"
import { IoCloseOutline } from 'react-icons/io5'

import Skype from "../../images/skype.svg"
import SkypeGif from "../../images/skype.gif"
import Telegram from "../../images/telegram.svg"
import TelegramGif from "../../images/telegram.gif"
import Whatsapp from "../../images/whatsapp.svg"
import WhatsappGif from "../../images/whatsapp.gif"
import User from "../../images/user.svg"

const TimeCountdown = () => {
  const data = useStaticQuery(graphql`{
    wp {
      themeGeneralSettings {
        commonSetting {
          becomeSponsorLink {
            ... on WpPage {
              link
            }
          }
          getTicketsLink {
            ... on WpPage {
              link
            }
          }
          countdownDay
          ourTeam {
            email
            name
            skypeLink
            tel
            telegramLink
            whatsappLink
            image {
              gatsbyImage(width: 310, height: 210, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }`)

  const [isModelOpen, setIsModelOpen] = useState(false)
  const time = data.wp.themeGeneralSettings.commonSetting;

  return (
    <>
      <section className={`${isModelOpen ? 'left-0' : '-left-full'} transition-all duration-500 w-full h-full z-[60] bg-[#f4f6fa] top-0 fixed overflow-y-scroll`}>
        <div className="px-5 pt-10 pb-[100px] sm:pb-[150px]">
          <IconContext.Provider value={{ size: 28 }}><IoCloseOutline onClick={() => setIsModelOpen(bool => !bool)} className="mb-4 ml-auto" /></IconContext.Provider>
          {
            data.wp.themeGeneralSettings.commonSetting.ourTeam.map((team, i) => {
              return (
                <div className="flex items-center mb-5 space-x-3 overflow-hidden bg-white div-rounded box-shadow sm:space-x-5" key={`phone-team-${i}`}>
                  <GatsbyImage image={team.image.gatsbyImage} alt={team.image.altText ?? ""} className="w-5/12 sm:w-4/12 h-[135px] sm:h-[165px]" />
                  <div className="w-7/12 py-4 md:py-0 sm:w-8/12 item-center">
                    <div className="text-lg font-medium capitalize sm:text-xl">{team.name}</div>
                    <a className="block mt-1 text-xs sm:text-base" href={`mailto:${team.email}`} target="_blank" rel="noreferrer">Email : {team.email}</a>
                    <a className="block mt-1 text-xs sm:text-base" href={`tel:${team.tel}`} target="_blank" rel="noreferrer">Tel : {team.tel}</a>

                    <div className="flex mt-2 space-x-2 sm:mt-4 sm:space-x-4">
                      <a href={team.whatsappLink} target="_blank" rel="noreferrer" className="relative">
                        <Whatsapp className="w-6 h-6 sm:w-8 sm:h-8" />
                        <img src={WhatsappGif} className="absolute top-0 w-6 opacity-0 sm:w-8 hover:opacity-100" alt="Our Team" />
                      </a>
                      <a href={team.skypeLink} target="_blank" rel="noreferrer" className="relative">
                        <Skype className="w-6 h-6 sm:w-8 sm:h-8" />
                        <img src={SkypeGif} className="absolute top-0 w-6 opacity-0 sm:w-8 hover:opacity-100" alt="Our Team" />
                      </a>
                      <a href={team.telegramLink} target="_blank" rel="noreferrer" className="relative">
                        <Telegram className="w-6 h-6 sm:w-8 sm:h-8" />
                        <img src={TelegramGif} className="absolute top-0 w-6 opacity-0 sm:w-8 hover:opacity-100" alt="Our Team" />
                      </a>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </section>

      <section className="fixed z-[99] bottom-0 w-full pt-2.5 pb-1.5 bg-gradient-to-r from-[var(--color-linear-countdown-1)] to-[var(--color-linear-countdown-2)]">
        <div className="container flex items-center justify-between mx-auto text-white">
          <div className="items-center block md:flex">
            <p className="mb-2 text-xs font-medium md:mb-0 md:text-xl md:mr-4 lg:mr-9">Ticket price increases in</p>
            <FlipCountdown
              hideYear
              hideMonth
              titlePosition='bottom'
              endAt={time.countdownDay}
            />
          </div>
          <div className="flex items-center space-x-2 sm:ml-4 lg:ml-0 lg:space-x-5">
            <Link to={time.becomeSponsorLink.link} className="btn !hidden lg:!block">Become sponsor</Link>
            <Link to={time.getTicketsLink.link} className="btn linear">Get tickets</Link>
            <div onClick={() => setIsModelOpen(bool => !bool)} role="presentation" className="flex items-center justify-center bg-white rounded-full w-7 h-7 lg:hidden">
              <User className="w-4" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TimeCountdown;